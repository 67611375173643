import React, { useState, useEffect } from 'react';
import { CityConfig } from '../types/city';

interface CityFormProps {
    config: CityConfig;
    onSave: (config: CityConfig) => void;
    hideButtons?: boolean;
    maxMarketingSpending?: number;
}

const SliderInput: React.FC<{
    label: string;
    name: string;
    value: number;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    min?: number;
    max?: number;
    step?: number;
}> = ({ label, name, value, onChange, min = 1, max = 5, step = 1 }) => {
    return (
        <div className="space-y-2">
            <div className="flex justify-between">
                <label className="block text-sm font-medium">{label}</label>
                <span className="text-sm text-gray-400">{value}</span>
            </div>
            <input
                type="range"
                name={name}
                value={value}
                onChange={onChange}
                min={min}
                max={max}
                step={step}
                className="w-full accent-[#01FF95] bg-[#3C3C38] rounded-lg appearance-none cursor-pointer"
            />
            <div className="flex justify-between text-xs text-gray-400">
                <span>Low</span>
                <span>High</span>
            </div>
        </div>
    );
};

const MonthSelect: React.FC<{
    label: string;
    name: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}> = ({ label, name, value, onChange }) => {
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    // Convert date string to month name
    const displayValue = value.includes('-') 
        ? months[parseInt(value.split('-')[1]) - 1]
        : months.includes(value) ? value : months[0];

    return (
        <div className="space-y-2">
            <label className="block text-sm font-medium">{label}</label>
            <select
                name={name}
                value={displayValue}
                onChange={onChange}
                className="w-full bg-[#3C3C38] p-2 rounded"
            >
                {months.map((month) => (
                    <option key={month} value={month}>
                        {month}
                    </option>
                ))}
            </select>
        </div>
    );
};

const CityForm: React.FC<CityFormProps> = ({ config, onSave, hideButtons = true, maxMarketingSpending = 100000 }) => {
    const [formData, setFormData] = useState<CityConfig>({
        ...config
    });

    useEffect(() => {
        setFormData(config);
    }, [config]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        
        let processedValue = value;
        if (name === 'peak_start_date') {
            // Convert month name to date format (using current year)
            const currentYear = new Date().getFullYear();
            const monthIndex = ['January', 'February', 'March', 'April', 'May', 'June',
                'July', 'August', 'September', 'October', 'November', 'December'].indexOf(value);
            if (monthIndex !== -1) {
                processedValue = `${currentYear}-${String(monthIndex + 1).padStart(2, '0')}-01`;
            }
        }

        const newFormData = {
            ...formData,
            [name]: name === 'launch_date' || name === 'peak_start_date'
                ? processedValue
                : Number(value)
        };
        setFormData(newFormData);
        if (formData[name as keyof CityConfig] !== newFormData[name as keyof CityConfig]) {
            onSave(newFormData);
        }
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        onSave(formData);
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-8">
            {/* Demo Section */}
            <div className="space-y-4">
                <h3 className="text-lg font-semibold text-[#01FF95]">Demo</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    <div className="space-y-2">
                        <label className="block text-sm font-medium">Population Size</label>
                        <input
                            type="number"
                            value={formData.population_size}
                            disabled
                            className="w-full bg-[#3C3C38] p-2 rounded opacity-50"
                        />
                    </div>
                    <div className="space-y-2">
                        <label className="block text-sm font-medium">Annual Tourists</label>
                        <input
                            type="number"
                            value={formData.annual_tourists}
                            disabled
                            className="w-full bg-[#3C3C38] p-2 rounded opacity-50"
                        />
                    </div>
                    <div className="space-y-2">
                        <label className="block text-sm font-medium">Monthly Tourists</label>
                        <input
                            type="number"
                            value={formData.monthly_tourists}
                            disabled
                            className="w-full bg-[#3C3C38] p-2 rounded opacity-50"
                        />
                    </div>
                    <div className="space-y-2">
                        <label className="block text-sm font-medium">Land Area</label>
                        <input
                            type="number"
                            name="land_area"
                            value={formData.land_area}
                            disabled
                            className="w-full bg-[#3C3C38] p-2 rounded opacity-50"
                        />
                    </div>
                    <div className="space-y-2">
                        <MonthSelect
                            label="Peak Start Month"
                            name="peak_start_date"
                            value={formData.peak_start_date}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="space-y-2">
                        <label className="block text-sm font-medium">Peak Duration</label>
                        <input
                            type="number"
                            name="peak_duration"
                            value={formData.peak_duration}
                            onChange={handleChange}
                            disabled
                            className="w-full bg-[#3C3C38] p-2 rounded opacity-50"
                        />
                    </div>
                </div>
            </div>

            {/* Operations Section */}
            <div className="space-y-4">
                <h3 className="text-lg font-semibold text-[#01FF95]">Operations</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    <div className="space-y-2">
                        <label className="block text-sm font-medium">Launch Date</label>
                        <input
                            type="date"
                            name="launch_date"
                            value={formData.launch_date}
                            onChange={handleChange}
                            className="w-full bg-[#3C3C38] p-2 rounded"
                        />
                    </div>
                    <div className="space-y-2">
                        <SliderInput
                            label="Provider Onboarding Strategy"
                            name="providers_onboarding_strategy"
                            value={formData.providers_onboarding_strategy}
                            onChange={handleChange}
                            min={1}
                            max={5}
                            step={1}
                        />
                    </div>
                    <div className="space-y-2">
                        <SliderInput
                            label="Marketing Spending"
                            name="demand_marketing_spend"
                            value={formData.demand_marketing_spend}
                            onChange={handleChange}
                            min={10000}
                            max={maxMarketingSpending}
                            step={10000}
                        />
                    </div>
                </div>
            </div>

            {/* Providers Section */}
            <div className="space-y-4">
                <h3 className="text-lg font-semibold text-[#01FF95]">Providers</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    <div className="space-y-2">
                        <label className="block text-sm font-medium">Total Providers</label>
                        <input
                            type="number"
                            name="providers_total_number"
                            value={formData.providers_total_number}
                            onChange={handleChange}
                            className="w-full bg-[#3C3C38] p-2 rounded opacity-50"
                        />
                    </div>
                    <div className="space-y-2">
                        <label className="block text-sm font-medium">Initial Providers</label>
                        <input
                            type="number"
                            name="providers_initial_number"
                            value={formData.providers_initial_number}
                            onChange={handleChange}
                            className="w-full bg-[#3C3C38] p-2 rounded"
                        />
                    </div>
                    <div className="space-y-2">
                        <SliderInput
                            label="Provider Conversion Rate"
                            name="provider_conversion_rate"
                            value={formData.provider_conversion_rate}
                            onChange={handleChange}
                            min={0}
                            max={1}
                            step={0.010}
                        />
                    </div>
                    <div className="space-y-2">
                        <SliderInput
                            label="Provider Target Penetration (%)"
                            name="provider_target_penetration"
                            value={formData.provider_target_penetration}
                            onChange={handleChange}
                            min={0}
                            max={1}
                            step={0.010}
                        />
                    </div>
                    <div className="space-y-2">
                        <SliderInput
                            label="Provider Penetration Speed"
                            name="provider_penetration_speed"
                            value={formData.provider_penetration_speed}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="space-y-2">
                        <SliderInput
                            label="Providers Ramping Period (Months)"
                            name="providers_ramping_period_months"
                            value={formData.providers_ramping_period_months}
                            onChange={handleChange}
                            min={0}
                            max={12}
                            step={1}
                        />
                    </div>
                </div>
            </div>

            {/* Demand - Tourists Section */}
            <div className="space-y-4">
                <h3 className="text-lg font-semibold text-[#01FF95]">Demand - Tourists</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    <div className="space-y-2">
                        <SliderInput
                            label="Tourist Conversion Rate"
                            name="tourist_conversion_rate"
                            value={formData.tourist_conversion_rate}
                            onChange={handleChange}
                            min={0}
                            max={0.3}
                            step={0.010}
                        />
                    </div>
                    <div className="space-y-2">
                        <SliderInput
                            label="Tourist Conversion Growth"
                            name="tourist_conversion_growth"
                            value={formData.tourist_conversion_growth}
                            onChange={handleChange}
                            min={0}
                            max={0.3}
                            step={0.010}
                        />
                    </div>
                    <div className="space-y-2">
                        <label className="block text-sm font-medium">Tourist Transaction Volume Average</label>
                        <input
                            type="number"
                            name="tourist_transaction_volume_average"
                            value={formData.tourist_transaction_volume_average}
                            onChange={handleChange}
                            min="0"
                            className="w-full bg-[#3C3C38] p-2 rounded"
                        />
                    </div>
                    <div className="space-y-2">
                        <SliderInput
                            label="Tourist Paid Transaction Rate"
                            name="tourist_paid_transaction_rate"
                            value={formData.tourist_paid_transaction_rate}
                            onChange={handleChange}
                            min={0}
                            max={0.1}
                            step={0.0001}
                            />
                    </div>
                    <div className="space-y-2">
                        <label className="block text-sm font-medium">Average Paid Transaction Amount</label>
                        <input
                            type="number"
                            name="tourist_average_paid_transaction_amount"
                            value={formData.tourist_average_paid_transaction_amount}
                            onChange={handleChange}
                            step="0.01"
                            min="0"
                            className="w-full bg-[#3C3C38] p-2 rounded"
                        />
                    </div>
                </div>
            </div>

            {/* Demand - Local Section */}
            <div className="space-y-4">
                <h3 className="text-lg font-semibold text-[#01FF95]">Demand - Local</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    <div className="space-y-2">
                        <SliderInput
                            label="Local Conversion Rate"
                            name="local_conversion_rate"
                            value={formData.local_conversion_rate}
                            onChange={handleChange}
                            min={0}
                            max={0.3}
                            step={0.010}
                        />
                    </div>
                    <div className="space-y-2">
                        <SliderInput
                            label="Local Conversion Growth"
                            name="local_conversion_growth"
                            value={formData.local_conversion_growth}
                            onChange={handleChange}
                            min={0}
                            max={0.3}
                            step={0.010}
                        />
                    </div>
                    <div className="space-y-2">
                        <SliderInput
                            label="Local Paid Transaction Rate"
                            name="local_paid_transaction_rate"
                            value={formData.local_paid_transaction_rate}
                            onChange={handleChange}
                            min={0}
                            max={0.1}
                            step={0.0001}
                        />
                    </div>
                    <div className="space-y-2">
                        <label className="block text-sm font-medium">Average Paid Transaction Amount</label>
                        <input
                            type="number"
                            name="local_average_paid_transaction_amount"
                            value={formData.local_average_paid_transaction_amount}
                            onChange={handleChange}
                            step="0.01"
                            min="0"
                            className="w-full bg-[#3C3C38] p-2 rounded"
                        />
                    </div>
                </div>
            </div>

            {!hideButtons && (
                <div>
                    <button
                        type="submit"
                        className="bg-[#01FF95] text-black px-4 py-2 rounded"
                    >
                        Save Changes
                    </button>
                </div>
            )}
        </form>
    );
};

export default CityForm;
