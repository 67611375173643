import React, { useState } from 'react';
import { GlobalParameters } from '../types/parameters';

interface SettingsModalProps {
  parameters: GlobalParameters;
  onSave: (parameters: GlobalParameters) => void;
  onClose: () => void;
  onScenarioSelect: (cities: string[]) => void;
}

export const SettingsModal: React.FC<SettingsModalProps> = ({ parameters, onSave, onClose, onScenarioSelect }) => {
  const [formData, setFormData] = useState<GlobalParameters>(parameters);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const updatedParams = {
      ...formData,
      [name]: Number(value)
    };
    setFormData(updatedParams);
    onSave(updatedParams);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSave(formData);
  };

  const handleScenarioChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedScenario = parameters.scenario.find(s => s.name === e.target.value);
    if (selectedScenario) {
      onScenarioSelect(selectedScenario.list);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-start justify-start z-50 pt-20 pl-20">
      <div className="bg-[#2C2C28] p-6 rounded-lg shadow-lg w-[600px]">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-bold text-[#01FF95]">Global Settings</h2>
          <button onClick={onClose} className="text-gray-400 hover:text-white">✕</button>
        </div>

        <div className="mb-6">
          <label className="block text-sm mb-2">Load Scenario</label>
          <select
            onChange={handleScenarioChange}
            className="w-full bg-[#3C3C38] p-2 rounded"
            defaultValue=""
          >
            <option value="">Select a scenario</option>
            {parameters.scenario.map(s => (
              <option key={s.name} value={s.name}>
                {s.name}
              </option>
            ))}
          </select>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <label className="block text-sm">Monthly Subscription Fee</label>
              <input
                type="number"
                name="monthly_subscription_fee"
                value={formData.monthly_subscription_fee}
                onChange={handleChange}
                className="w-full bg-[#3C3C38] p-2 rounded"
              />
            </div>

            <div className="space-y-2">
              <label className="block text-sm">Transaction Cost</label>
              <input
                type="number"
                name="transaction_cost"
                value={formData.transaction_cost}
                onChange={handleChange}
                step="0.01"
                className="w-full bg-[#3C3C38] p-2 rounded"
              />
            </div>

            <div className="space-y-2">
              <label className="block text-sm">Paid Transaction Cost %</label>
              <input
                type="number"
                name="paid_transaction_cost_percentage"
                value={formData.paid_transaction_cost_percentage}
                onChange={handleChange}
                step="0.001"
                className="w-full bg-[#3C3C38] p-2 rounded"
              />
            </div>

            <div className="space-y-2">
              <label className="block text-sm">Paid Transaction Revenue %</label>
              <input
                type="number"
                name="paid_transaction_revenue_percentage"
                value={formData.paid_transaction_revenue_percentage}
                onChange={handleChange}
                step="0.001"
                className="w-full bg-[#3C3C38] p-2 rounded"
              />
            </div>

            <div className="space-y-2">
              <label className="block text-sm">Max Marketing Spending</label>
              <input
                type="number"
                name="max_marketing_spending"
                value={formData.max_marketing_spending}
                onChange={handleChange}
                className="w-full bg-[#3C3C38] p-2 rounded"
              />
            </div>

            <div className="space-y-2">
              <label className="block text-sm">Projection Months</label>
              <input
                type="number"
                name="projection_months"
                value={formData.projection_months}
                onChange={handleChange}
                min="12"
                max="60"
                className="w-full bg-[#3C3C38] p-2 rounded"
              />
            </div>

            <div className="space-y-2">
              <label className="block text-sm">Global Infrastructure Cost</label>
              <input
                type="number"
                name="global_infrastructure_cost"
                value={formData.global_infrastructure_cost}
                onChange={handleChange}
                className="w-full bg-[#3C3C38] p-2 rounded"
              />
            </div>

            <div className="space-y-2">
              <label className="block text-sm">Global Staff Cost</label>
              <input
                type="number"
                name="global_staff_cost"
                value={formData.global_staff_cost}
                onChange={handleChange}
                className="w-full bg-[#3C3C38] p-2 rounded"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};