import * as XLSX from "xlsx";
import { CityConfig } from "../types/city";
import { MetricsData, MetricsResponse } from "../types/metrics";

interface ExcelSection {
  title: string;
  metrics: string[];
}

const parameterSections = [
  {
    title: "Demo Parameters",
    params: [
      "population_size",
      "annual_tourists",
      "monthly_tourists",
      "land_area",
      "peak_start_date",
      "peak_duration",
    ],
  },
  {
    title: "Operations Parameters",
    params: [
      "launch_date",
      "providers_onboarding_strategy",
      "demand_marketing_spend",
    ],
  },
  {
    title: "Provider Parameters",
    params: [
      "providers_total_number",
      "providers_initial_number",
      "providers_ramping_period_months",
    ],
  },
  {
    title: "Tourist Demand Parameters",
    params: [
      "tourist_conversion_rate",
      "tourist_conversion_growth",
      "tourist_paid_transaction_rate",
      "tourist_average_paid_transaction_amount",
    ],
  },
  {
    title: "Local Demand Parameters",
    params: [
      "local_conversion_rate",
      "local_conversion_growth",
      "local_paid_transaction_rate",
      "local_average_paid_transaction_amount",
    ],
  },
];

const metricSections: ExcelSection[] = [
  {
    title: "Revenue Metrics",
    metrics: ["revenue", "transaction_revenue", "aaas_revenue"],
  },
  {
    title: "Cost Metrics",
    metrics: [
      "aaas_costs",
      "city_operations_cost",
      "city_operations_staff_cost",
      "city_operations_infrastructure_cost",
      "city_operations_marketing_cost",
      "onboarding_cost",
      "total_costs",
    ],
  },
  {
    title: "Global Operations",
    metrics: [
      "global_operations_staff_cost",
      "global_operations_infrastructure_cost",
      "global_operations_total_cost",
    ],
  },
  {
    title: "Provider Metrics",
    metrics: ["total_providers", "new_providers", "active_providers"],
  },
  {
    title: "Demand Metrics",
    metrics: [
      "monthly_tourist_demand",
      "monthly_local_demand",
      "seasonal_tourist_baseline",
    ],
  },
  {
    title: "Transaction Metrics",
    metrics: ["total_transactions", "paid_transactions"],
  },
];

const createParametersSection = (configs: { [key: string]: CityConfig }) => {
  const rows: (string | number)[][] = [];
  rows.push(["Parameters"]);
  rows.push([]); // Empty row for spacing

  // Create headers with city names
  const cityNames = Object.keys(configs);
  rows.push(["Parameter", ...cityNames]);

  parameterSections.forEach((section) => {
    rows.push([section.title]);
    section.params.forEach((param) => {
      const paramName = param
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");

      const values = cityNames.map(
        (cityName) => configs[cityName][param as keyof CityConfig]
      );
      rows.push([paramName, ...values]);
    });
    rows.push([]); // Empty row for spacing
  });

  return rows;
};

export const exportCityMetrics = (
  cityName: string,
  data: MetricsData[],
  config: CityConfig
) => {
  const wb = XLSX.utils.book_new();

  // Create metrics sheet
  const metricsData = createMetricsSection(data);
  const ws_metrics = XLSX.utils.aoa_to_sheet(metricsData);
  XLSX.utils.book_append_sheet(wb, ws_metrics, `${cityName} Metrics`);

  // Create parameters sheet
  const parameterRows = createParametersSection({ [cityName]: config });
  const ws_params = XLSX.utils.aoa_to_sheet(parameterRows);
  XLSX.utils.book_append_sheet(wb, ws_params, `${cityName} Parameters`);

  XLSX.writeFile(wb, `${cityName}_metrics.xlsx`);
};

const createMetricsSection = (data: MetricsData[]) => {
  const excelData: { [key: string]: any } = {};

  metricSections.forEach((section) => {
    section.metrics.forEach((metric) => {
      excelData[metric] = {
        metric: metric
          .split("_")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" "),
        section: section.title,
      };
    });
  });

  data.forEach((metricData) => {
    const date = metricData.date;
    metricSections.forEach((section) => {
      section.metrics.forEach((metric) => {
        excelData[metric][date] = metricData[metric as keyof MetricsData];
      });
    });
  });

  const headers = ["Metric", "Section", ...data.map((d) => d.date)];
  const rows = Object.values(excelData).map((row) => [
    row.metric,
    row.section,
    ...data.map((d) => row[d.date]),
  ]);

  return [headers, ...rows];
};

export const exportAllCityMetrics = async (
  citiesData: { [key: string]: MetricsData[] },
  cityConfigs: { [key: string]: CityConfig },
  globalOpsMetrics: MetricsResponse
) => {
  console.log("Starting exportAllCityMetrics");

  const wb = XLSX.utils.book_new();

  // Get all unique dates
  const allDates = new Set<string>();
  Object.values(citiesData).forEach((cityData) => {
    cityData.forEach((metric) => allDates.add(metric.date));
  });
  globalOpsMetrics.metrics.forEach((metric: MetricsData) =>
    allDates.add(metric.date)
  );
  const sortedDates = Array.from(allDates).sort();

  // Create individual sheets for each city
  Object.entries(citiesData).forEach(([cityName, cityData]) => {
    const metricsData = createMetricsSection(cityData);
    const ws_city_metrics = XLSX.utils.aoa_to_sheet(metricsData);
    XLSX.utils.book_append_sheet(wb, ws_city_metrics, `${cityName} Metrics`);
  });

  // Create aggregated metrics sheet
  const headers = ["Metric", "Section"];
  sortedDates.forEach((date) => headers.push(date));

  // Create data rows with aggregated values
  const rows: any[][] = [];
  metricSections.forEach((section) => {
    section.metrics.forEach((metric) => {
      console.log(`Processing metric: ${metric} in section: ${section.title}`);

      const row: (string | number)[] = [
        metric
          .split("_")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" "),
        section.title,
      ];

      sortedDates.forEach((date) => {
        let totalValue = 0;

        if (metric.startsWith("global_operations")) {
          const globalMetric = globalOpsMetrics.metrics.find(
            (m) => m.date === date
          );
          if (globalMetric) {
            totalValue = Number(globalMetric[metric as keyof MetricsData]) || 0;
            console.log(
              `Found global operations value from globalOpsMetrics: ${totalValue} for ${metric} on ${date}`
            );
          }
        } else {
          Object.values(citiesData).forEach((cityData) => {
            const metricData = cityData.find((d) => d.date === date);
            if (metricData) {
              totalValue +=
                Number(metricData[metric as keyof MetricsData]) || 0;
            }
          });
        }

        console.log(`Final value for ${metric} on ${date}: ${totalValue}`);
        row.push(totalValue);
      });

      rows.push(row);
    });
  });

  const ws_aggregated = XLSX.utils.aoa_to_sheet([headers, ...rows]);
  XLSX.utils.book_append_sheet(wb, ws_aggregated, "Aggregated Metrics");

  // Create parameters sheet
  const parameterRows = createParametersSection(cityConfigs);
  const ws_params = XLSX.utils.aoa_to_sheet(parameterRows);
  XLSX.utils.book_append_sheet(wb, ws_params, "All Cities Parameters");

  console.log("Completed export with:", {
    numberOfSheets: wb.SheetNames.length,
    sheetNames: wb.SheetNames,
  });

  XLSX.writeFile(wb, "all_cities_metrics.xlsx");
};

export interface GlobalParameters {
  projection_months: number;
  tourist_base_rate: number;
  local_base_rate: number;
  tourist_repeat_rate: number;
  local_repeat_rate: number;
  transaction_cost: number;
  paid_transaction_cost_percentage: number;
  monthly_subscription_fee: number;
  paid_transaction_revenue_percentage: number;
  provider_base_monthly_acquisitions: {
    [key: string]: number;
  };
  provider_strategy_multipliers: {
    [key: string]: number;
  };
  demand_multipliers: {
    [key: string]: number;
  };
  max_marketing_spending: number;
}
