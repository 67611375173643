import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from 'recharts';
import { MetricsData } from '../types/metrics';

interface ReferenceLineConfig {
    y: number;
    yAxisId: "left" | "right";
    stroke: string;
    strokeDasharray: string;
    label: {
        value: string;
        position: 'left' | 'right';
        fill: string;
    };
}

interface CityMetricsChartProps {
    data: MetricsData[];
    metrics: string[];
    colors: string[];
    totalProviders?: number;
    providerPenetrationTarget?: number;
    dualAxis?: boolean;
    rightAxisMetrics?: string[];
    rightAxisDomain?: [number | 'auto', number | 'auto'];
    leftAxisDomain?: [number | 'auto', number | 'auto'];
    referenceLines?: ReferenceLineConfig[];
    cityLaunches?: { [key: string]: string };
    activePeriodStart?: string;
    dashedMetrics?: string[];
    lineThickness?: { [key: string]: number };
    yAxisColor?: string;
}

export const CityMetricsChart: React.FC<CityMetricsChartProps> = ({ 
    data, 
    metrics, 
    colors,
    totalProviders,
    providerPenetrationTarget,
    dualAxis = false,
    rightAxisMetrics = [],
    rightAxisDomain,
    leftAxisDomain,
    referenceLines,
    cityLaunches,
    activePeriodStart,
    dashedMetrics,
    lineThickness,
    yAxisColor
}) => {
    
    
    const isProviderChart = metrics.includes('total_providers');
    const isDemandChart = metrics.includes('monthly_tourist_demand');
    
    
    
    return (
        <div className="h-[400px] w-full">
            <ResponsiveContainer>
                <LineChart data={data} margin={{ top: 20, right: 60, left: 60, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" stroke="#3C3C38" />
                    <XAxis dataKey="date" stroke="#ffffff" />
                    <YAxis 
                        yAxisId="left"
                        stroke={yAxisColor || colors[0]}
                        domain={leftAxisDomain || ['auto', 'auto']}
                        scale={isDemandChart ? 'log' : 'auto'}
                        allowDataOverflow={true}
                        tickFormatter={(value) => value.toLocaleString()}
                    />
                    {dualAxis && (
                        <YAxis 
                            yAxisId="right" 
                            orientation="right" 
                            stroke={colors[1]}
                            domain={rightAxisDomain || ['auto', 'auto']}
                            scale={isDemandChart ? 'log' : 'auto'}
                            allowDataOverflow={true}
                            tickFormatter={(value) => value.toLocaleString()}
                        />
                    )}
                    <Tooltip 
                        contentStyle={{ backgroundColor: '#2C2C28', border: 'none', borderRadius: '4px' }}
                        labelStyle={{ color: '#ffffff' }}
                        formatter={(value: any) => Number(value).toLocaleString()}
                    />
                    <Legend />
                    
                    {/* Launch date reference line */}
                    {cityLaunches && Object.entries(cityLaunches).map(([cityName, launchDate]) => (
                        <ReferenceLine
                            key={cityName}
                            x={launchDate}
                            yAxisId="left"
                            stroke="#01FF95"
                            strokeDasharray="3 3"
                            segment={[{ y: 0 }, { y: '80%' }]}
                            label={{
                                value: cityName,
                                position: 'top',
                                fill: '#01FF95',
                                angle: 0,
                                offset: 10
                            }}
                        />
                    ))}

                    {/* Provider reference lines */}
                    {isProviderChart && totalProviders && (
                        <ReferenceLine 
                            yAxisId="left"
                            y={totalProviders} 
                            stroke="#FF4444" 
                            strokeDasharray="3 3"
                            label={{ 
                                value: 'Total Providers', 
                                position: 'right',
                                fill: '#FF4444'
                            }}
                            ifOverflow="extendDomain"
                        />
                    )}
                    {isProviderChart && providerPenetrationTarget && (
                        <ReferenceLine 
                            yAxisId="left"
                            y={providerPenetrationTarget} 
                            stroke="#FFB800" 
                            strokeDasharray="3 3"
                            label={{ 
                                value: 'Target Penetration', 
                                position: 'right',
                                fill: '#FFB800'
                            }}
                            ifOverflow="extendDomain"
                        />
                    )}

                    {/* Demand reference lines */}
                    {referenceLines?.map((refLine, index) => (
                        <ReferenceLine
                            key={index}
                            {...refLine}
                            ifOverflow="extendDomain"
                        />
                    ))}

                    {metrics.map((metric, index) => (
                        <Line 
                            key={metric}
                            yAxisId={rightAxisMetrics.includes(metric) ? "right" : "left"}
                            type="monotone" 
                            dataKey={metric} 
                            stroke={colors[index]} 
                            name={metric.split('_').map(word => {
                                if (metric === 'revenue') return 'Revenue';
                                if (metric === 'costs') return 'Costs';
                                if (metric === 'net_position') return 'Profit';
                                return word.charAt(0).toUpperCase() + word.slice(1);
                            }).join(' ')} 
                            dot={false}
                            strokeWidth={lineThickness?.[metric] || 1}
                            strokeDasharray={dashedMetrics?.includes(metric) ? "3 3" : undefined}
                            strokeOpacity={1}
                        />
                    ))}
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};
