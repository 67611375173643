import React, { useState, useEffect } from 'react';
import { CityMetricsChart } from './CityMetricsChart';
import { MetricsResponse } from '../types/metrics';
import { UsersIcon, ChartBarIcon, ScaleIcon, BanknotesIcon, CalculatorIcon, ChartPieIcon } from '@heroicons/react/24/outline';
import { MetricsBoxes } from './MetricsBoxes';
import { CostStackedBarChart } from './CostStackedBarChart';

interface EnhancedVisualizationsProps {
    data: MetricsResponse;
    totalProviders?: number;
    providerPenetration?: number;
    cityLaunches?: { [key: string]: string };
    launchDate?: string;
    rampingPeriodMonths?: number;
    onExportMetrics?: () => void;
    showExportButton?: boolean;
}

const CHART_TYPES = {
    DEMAND: 'Demand',
    PROVIDERS: 'Providers',
    TRANSACTIONS: 'Transactions',
    FINANCIALS: 'Revenue',
    COSTS: 'Costs',
    MARGINS: 'Margins'
} as const;

type ChartType = keyof typeof CHART_TYPES;

const CHART_CONFIG = {
    DEMAND: {
        icon: ScaleIcon,
        label: 'Demand',
    },
    PROVIDERS: {
        icon: UsersIcon,
        label: 'Providers',
    },
    TRANSACTIONS: {
        icon: ChartBarIcon,
        label: 'Transactions',
    },
    FINANCIALS: {
        icon: BanknotesIcon,
        label: 'Revenue',
    },
    COSTS: {
        icon: CalculatorIcon,
        label: 'Costs',
    },
    MARGINS: {
        icon: ChartPieIcon,
        label: 'Margins',
    },
} as const;

export const EnhancedVisualizations: React.FC<EnhancedVisualizationsProps> = ({ 
    data,
    totalProviders,
    providerPenetration,
    cityLaunches,
    launchDate,
    rampingPeriodMonths = 3,
    onExportMetrics,
    showExportButton = false
}) => {
    const [selectedChart, setSelectedChart] = useState<ChartType>('PROVIDERS');
    const [selectedDate, setSelectedDate] = useState('2025-12-01');

    useEffect(() => {
        if (data.metrics.length > 0) {
            const dates = data.metrics.map(m => m.date);
            const defaultDate = dates.find(d => d.startsWith('2025-12')) || dates[dates.length - 1];
            setSelectedDate(defaultDate);
        }
    }, [data.metrics]);

    const providerPenetrationTarget = totalProviders && providerPenetration 
        ? totalProviders * providerPenetration 
        : undefined;

    const getActivePeriodStart = (launchDate: string) => {
        const date = new Date(launchDate);
        return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-01`;
    };

    const renderChart = () => {
        const activePeriodStart = launchDate 
            ? getActivePeriodStart(launchDate)
            : cityLaunches 
                ? getActivePeriodStart(Object.values(cityLaunches)[0])
                : undefined;

        const currentLaunches = launchDate 
            ? { [data.parameters.name || 'City']: launchDate }
            : cityLaunches;

        switch (selectedChart) {
            case 'TRANSACTIONS':
                return (
                    <CityMetricsChart 
                        data={data.metrics}
                        metrics={['total_transactions', 'paid_transactions']}
                        colors={['#01FF95', '#FFFF09']}
                        cityLaunches={currentLaunches}
                        activePeriodStart={activePeriodStart}
                    />
                );
            case 'DEMAND':
                const maxTouristDemand = data.parameters?.monthly_tourists || 2500000;
                const maxLocalDemand = data.parameters?.population_size || 2161000;
                
                return (
                    <CityMetricsChart 
                        data={data.metrics}
                        metrics={['monthly_tourist_demand', 'monthly_local_demand', 'seasonal_tourist_baseline']}
                        colors={['#01FF95', '#FFFF09', '#01FF95']}
                        dualAxis={true}
                        rightAxisMetrics={['monthly_local_demand']}
                        leftAxisDomain={[1, maxTouristDemand*1.5]}
                        rightAxisDomain={[1, maxLocalDemand]}
                        cityLaunches={currentLaunches}
                        activePeriodStart={activePeriodStart}
                        referenceLines={[
                            {
                                y: maxLocalDemand,
                                yAxisId: "right",
                                stroke: "#FFFF09",
                                strokeDasharray: "3 3",
                                label: {
                                    value: `Total Local Capacity`,
                                    position: 'right',
                                    fill: '#FFFF09'
                                }
                            }
                        ]}
                        dashedMetrics={['seasonal_tourist_baseline']}
                    />
                );
            case 'FINANCIALS':
                return (
                    <CityMetricsChart 
                        data={data.metrics}
                        metrics={['revenue', 'aaas_revenue', 'transaction_revenue']}
                        colors={['#01FF95', '#FFFF09', '#00e085']}
                    />
                );
            case 'COSTS':
                return (
                    <CostStackedBarChart 
                        data={data.metrics}
                    />
                );
            case 'MARGINS':
                return (
                    <CityMetricsChart 
                        data={data.metrics}
                        metrics={['revenue', 'total_costs', 'net_position']}
                        colors={['#FFFF09', '#FF4444', '#01FF95']}
                        dashedMetrics={['revenue', 'total_costs']}
                        lineThickness={{
                            'net_position': 3,
                            'revenue': 1,
                            'total_costs': 1
                        }}
                        yAxisColor="#01FF95"
                    />
                );
            default:
                return (
                    <CityMetricsChart 
                        data={data.metrics}
                        metrics={['total_providers', 'new_providers']}
                        colors={['#01FF95', '#FFFF09']}
                        totalProviders={totalProviders}
                        providerPenetrationTarget={providerPenetrationTarget}
                        cityLaunches={cityLaunches}
                    />
                );
        }
    };

    return (
        <div className="bg-[#2C2C28] p-6 rounded-lg shadow-lg">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold text-[#01FF95]">
                    {CHART_CONFIG[selectedChart].label}
                </h2>
                <div className="flex gap-2">
                    {showExportButton && (
                        <button
                            onClick={onExportMetrics}
                            className="px-4 py-2 rounded bg-[#4C4C48] text-white hover:bg-[#5C5C58] flex items-center gap-2"
                        >
                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                            </svg>
                            Export All
                        </button>
                    )}
                    {Object.entries(CHART_CONFIG).map(([key, config]) => {
                        const Icon = config.icon;
                        return (
                            <div key={key} className="relative group">
                                <button
                                    onClick={() => setSelectedChart(key as ChartType)}
                                    className={`p-2 rounded hover:bg-[#4C4C48] transition-colors ${
                                        selectedChart === key 
                                            ? 'bg-[#01FF95] text-black' 
                                            : 'bg-[#3C3C38] text-white'
                                    }`}
                                >
                                    <Icon className="w-6 h-6" />
                                </button>
                                <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 bg-[#2C2C28] text-white text-sm rounded opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap">
                                    {config.label}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            {renderChart()}
            <MetricsBoxes 
                data={data.metrics}
                selectedDate={selectedDate}
                onDateChange={setSelectedDate}
            />
        </div>
    );
};
