import axios from "axios";
import { CityConfig } from "../types/city";
import { MetricsResponse } from "../types/metrics";
import { GlobalParameters } from "../types/parameters";

const API_BASE_URL = process.env.REACT_APP_API_URL;

if (!API_BASE_URL) {
  throw new Error("REACT_APP_API_URL environment variable is not set");
}

export const api = {
  getCities: async (): Promise<string[]> => {
    try {
      const response = await axios.get<{ cities: string[] }>(
        `${API_BASE_URL}/cities`
      );
      return response.data.cities;
    } catch (error) {
      console.error("Error fetching cities:", error);
      throw new Error("Failed to fetch cities");
    }
  },

  getGlobalParameters: async (): Promise<GlobalParameters> => {
    try {
      const response = await axios.get<GlobalParameters>(
        `${API_BASE_URL}/global-parameters`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching global parameters:", error);
      throw new Error("Failed to fetch global parameters");
    }
  },

  saveGlobalParameters: async (parameters: GlobalParameters): Promise<void> => {
    try {
      await axios.post(`${API_BASE_URL}/global-parameters`, parameters);
    } catch (error) {
      console.error("Error saving global parameters:", error);
      throw new Error("Failed to save global parameters");
    }
  },

  getCityConfig: async (cityName: string): Promise<CityConfig> => {
    try {
      const response = await axios.get<CityConfig>(
        `${API_BASE_URL}/cities/${cityName}`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching city config:", error);
      throw new Error(`Failed to fetch config for ${cityName}`);
    }
  },

  saveCityConfig: async (cityName: string, config: CityConfig) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/cities/${cityName}`,
        config
      );
      return response.data;
    } catch (error) {
      console.error("Error saving city config:", error);
      throw new Error(`Failed to save config for ${cityName}`);
    }
  },

  getCityMetrics: async (
    cityName: string,
    config: CityConfig
  ): Promise<MetricsResponse> => {
    try {
      const response = await axios.post<MetricsResponse>(
        `${API_BASE_URL}/cities/${cityName}/metrics`,
        config
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching city metrics:", error);
      throw new Error(`Failed to fetch metrics for ${cityName}`);
    }
  },

  getGlobalOperationsMetrics: async (): Promise<MetricsResponse> => {
    try {
      const response = await axios.get<MetricsResponse>(
        `${API_BASE_URL}/global-operations`
      );
      console.log("Global operations metrics:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching global operations metrics:", error);
      throw new Error("Failed to fetch global operations metrics");
    }
  },
};
