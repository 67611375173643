import { Cog6ToothIcon, XMarkIcon } from "@heroicons/react/24/outline";
import React, { useCallback, useEffect, useState } from "react";
import logo from "../assets/logo.png";
import { api } from "../services/api";
import { CityConfig } from "../types/city";
import { MetricsResponse } from "../types/metrics";
import { GlobalParameters } from "../types/parameters";
import { exportAllCityMetrics, exportCityMetrics } from "../utils/excelExport";
import CityForm from "./CityForm";
import { EnhancedVisualizations } from "./EnhancedVisualizations";
import { SettingsModal } from "./SettingsModal";
import TreehouseMetrics from "./TreehouseMetrics";

export const CityConfigurator: React.FC = () => {
  const [cities, setCities] = useState<string[]>([]);
  const [configuredCities, setConfiguredCities] = useState<string[]>([]);
  const [cityConfigs, setCityConfigs] = useState<{ [key: string]: CityConfig }>(
    {}
  );
  const [metricsData, setMetricsData] = useState<{
    [key: string]: MetricsResponse;
  }>({});
  const [expandedForms, setExpandedForms] = useState<{
    [key: string]: boolean;
  }>({});
  const [approvedConfigs, setApprovedConfigs] = useState<{
    [key: string]: CityConfig;
  }>({});
  const [error, setError] = useState<string | null>(null);
  const [isTreehouseExpanded, setIsTreehouseExpanded] = useState(false);
  const [aggregatedMetrics, setAggregatedMetrics] =
    useState<MetricsResponse | null>(null);
  const [maxMarketingSpending, setMaxMarketingSpending] =
    useState<number>(100000);
  const [showSettings, setShowSettings] = useState(false);
  const [globalParameters, setGlobalParameters] =
    useState<GlobalParameters | null>(null);

  const generateAggregatedMetrics = useCallback(async () => {
    try {
      if (Object.keys(approvedConfigs).length > 0) {
        // Find earliest launch date among approved cities
        const earliestLaunchDate = new Date(
          Math.min(
            ...Object.values(approvedConfigs).map((config) =>
              new Date(config.launch_date).getTime()
            )
          )
        );

        // Set start date to 1 year before earliest launch date
        const startDate = new Date(earliestLaunchDate);
        startDate.setFullYear(startDate.getFullYear() - 1);
        startDate.setDate(1);
        startDate.setHours(0, 0, 0, 0);

        // Generate dates for 60 months
        const dates = Array.from({ length: 72 }, (_, i) => {
          const date = new Date(startDate);
          date.setMonth(date.getMonth() + i);
          date.setDate(1);
          return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-01`;
        });

        // Fetch all metrics in parallel
        const [allMetrics, globalOpsMetrics] = await Promise.all([
          Promise.all(
            Object.keys(approvedConfigs).map(async (city) =>
              api.getCityMetrics(city, approvedConfigs[city])
            )
          ),
          api.getGlobalOperationsMetrics(),
        ]);

        if (!allMetrics.length || !allMetrics[0]) {
          setAggregatedMetrics(null);
          return;
        }

        // Initialize aggregated metrics...
        const aggregated: MetricsResponse = {
          parameters: allMetrics[0].parameters,
          metrics: dates.map((date) => ({
            date,
            total_providers: 0,
            new_providers: 0,
            revenue: 0,
            transaction_revenue: 0,
            aaas_revenue: 0,
            net_position: 0,
            total_demand: 0,
            active_providers: 0,
            total_transactions: 0,
            paid_transactions: 0,
            aaas_costs: 0,
            city_operations_cost: 0,
            city_operations_staff_cost: 0,
            city_operations_infrastructure_cost: 0,
            city_operations_marketing_cost: 0,
            onboarding_cost: 0,
            total_costs: 0,
            monthly_tourist_demand: 0,
            monthly_local_demand: 0,
            seasonal_tourist_baseline: 0,
            global_operations_staff_cost: 0,
            global_operations_infrastructure_cost: 0,
            global_operations_total_cost: 0,
          })),
        };

        // Sum up metrics for each date across all cities
        aggregated.metrics = aggregated.metrics.map((baseMetric) => {
          const date = baseMetric.date;

          // Get global operations costs for this date
          const globalMetric = globalOpsMetrics.metrics.find(
            (m) => m.date === date
          );

          // Sum city-specific metrics
          const cityMetrics = allMetrics.reduce((acc, cityMetrics) => {
            const metric =
              cityMetrics.metrics.find((m) => m.date === date) || baseMetric;
            return {
              ...acc,
              total_providers: acc.total_providers + metric.total_providers,
              new_providers: acc.new_providers + metric.new_providers,
              transaction_revenue:
                acc.transaction_revenue + (metric.transaction_revenue ?? 0),
              aaas_revenue: acc.aaas_revenue + (metric.aaas_revenue ?? 0),
              total_demand: acc.total_demand + metric.total_demand,
              active_providers: acc.active_providers + metric.active_providers,
              total_transactions:
                acc.total_transactions + metric.total_transactions,
              paid_transactions:
                acc.paid_transactions + metric.paid_transactions,
              aaas_costs: acc.aaas_costs + (metric.aaas_costs ?? 0),
              city_operations_staff_cost:
                acc.city_operations_staff_cost +
                (metric.city_operations_staff_cost ?? 0),
              city_operations_infrastructure_cost:
                acc.city_operations_infrastructure_cost +
                (metric.city_operations_infrastructure_cost ?? 0),
              city_operations_marketing_cost:
                acc.city_operations_marketing_cost +
                (metric.city_operations_marketing_cost ?? 0),
              onboarding_cost:
                acc.onboarding_cost + (metric.onboarding_cost ?? 0),
              monthly_tourist_demand:
                acc.monthly_tourist_demand + metric.monthly_tourist_demand,
              monthly_local_demand:
                acc.monthly_local_demand + metric.monthly_local_demand,
              seasonal_tourist_baseline:
                acc.seasonal_tourist_baseline +
                metric.seasonal_tourist_baseline,
              global_operations_staff_cost:
                globalMetric?.global_operations_staff_cost || 0,
              global_operations_infrastructure_cost:
                globalMetric?.global_operations_infrastructure_cost || 0,
              global_operations_total_cost:
                globalMetric?.global_operations_total_cost || 0,
            };
          }, baseMetric);
          // Add global operations costs
          return {
            ...cityMetrics,
            revenue: cityMetrics.transaction_revenue + cityMetrics.aaas_revenue,
            total_costs:
              cityMetrics.aaas_costs +
              cityMetrics.city_operations_staff_cost +
              cityMetrics.city_operations_infrastructure_cost +
              cityMetrics.city_operations_marketing_cost +
              cityMetrics.onboarding_cost +
              cityMetrics.global_operations_total_cost,
            net_position:
              cityMetrics.transaction_revenue +
              cityMetrics.aaas_revenue -
              (cityMetrics.aaas_costs +
                cityMetrics.city_operations_staff_cost +
                cityMetrics.city_operations_infrastructure_cost +
                cityMetrics.city_operations_marketing_cost +
                cityMetrics.onboarding_cost +
                cityMetrics.global_operations_total_cost),
          };
        });

        setAggregatedMetrics(aggregated);
      } else {
        setAggregatedMetrics(null);
      }
    } catch (err) {
      setError(
        err instanceof Error
          ? err.message
          : "Failed to generate aggregated metrics"
      );
    }
  }, [approvedConfigs]);

  useEffect(() => {
    loadCities();
  }, []);

  useEffect(() => {
    if (isTreehouseExpanded) {
      generateAggregatedMetrics();
    }
  }, [approvedConfigs, isTreehouseExpanded, generateAggregatedMetrics]);

  useEffect(() => {
    const loadGlobalParameters = async () => {
      try {
        const params = await api.getGlobalParameters();
        setGlobalParameters(params);
        setMaxMarketingSpending(params.max_marketing_spending);
      } catch (err) {
        setError(
          err instanceof Error
            ? err.message
            : "Failed to load global parameters"
        );
      }
    };
    loadGlobalParameters();
  }, []);

  const loadCities = async () => {
    const cityList = await api.getCities();
    const globalParams = await api.getGlobalParameters();
    setCities(cityList);
    setMaxMarketingSpending(globalParams.max_marketing_spending);
  };

  const handleAddCity = async (cityName: string) => {
    try {
      setError(null);
      const config = await api.getCityConfig(cityName);
      setConfiguredCities((prev) => [...prev, cityName]);
      setCityConfigs((prev) => ({ ...prev, [cityName]: config }));
      setExpandedForms((prev) => ({ ...prev, [cityName]: true }));

      // Generate initial visualization with the loaded config
      const data = await api.getCityMetrics(cityName, config);
      setMetricsData((prev) => ({ ...prev, [cityName]: data }));
    } catch (err) {
      setError(
        err instanceof Error ? err.message : "Failed to load city configuration"
      );
    }
  };

  const handleSaveConfig = async (cityName: string, config: CityConfig) => {
    try {
      await api.saveCityConfig(cityName, config);
      setCityConfigs((prev) => ({ ...prev, [cityName]: config }));
    } catch (err) {
      setError(
        err instanceof Error
          ? err.message
          : "Failed to update city configuration"
      );
    }
  };

  const handleConfigChange = async (cityName: string, config: CityConfig) => {
    try {
      // Calculate start date (12 months before launch)
      const launchDate = new Date(config.launch_date);
      const startDate = new Date(launchDate);
      startDate.setFullYear(startDate.getFullYear() - 1);
      startDate.setDate(1);

      const startDateStr = startDate.toISOString().split("T")[0];

      // Add startDate to the config before getting metrics
      const configWithStartDate = {
        ...config,
        start_date: startDateStr,
        metrics_start_date: startDateStr,
      };

      setCityConfigs((prev) => ({ ...prev, [cityName]: configWithStartDate }));
      const data = await api.getCityMetrics(cityName, configWithStartDate);

      setMetricsData((prev) => ({ ...prev, [cityName]: data }));

      // If the city is approved, update its config in approvedConfigs and regenerate aggregated metrics
      if (approvedConfigs[cityName]) {
        setApprovedConfigs((prev) => ({
          ...prev,
          [cityName]: configWithStartDate,
        }));

        // Only regenerate if treehouse is expanded
        if (isTreehouseExpanded) {
          await generateAggregatedMetrics();
        }
      }
    } catch (err) {
      setError(
        err instanceof Error ? err.message : "Failed to update visualizations"
      );
    }
  };

  const handleApproveConfig = (cityName: string) => {
    const config = cityConfigs[cityName];
    if (config) {
      if (approvedConfigs[cityName]) {
        // If already approved, remove from approved configs
        setApprovedConfigs((prev) => {
          const newConfigs = { ...prev };
          delete newConfigs[cityName];
          return newConfigs;
        });
      } else {
        // If not approved, add to approved configs
        setApprovedConfigs((prev) => ({
          ...prev,
          [cityName]: config,
        }));
      }
    }
  };

  const handleRemoveCity = (cityName: string) => {
    setConfiguredCities((prev) => prev.filter((city) => city !== cityName));
    setApprovedConfigs((prev) => {
      const newConfigs = { ...prev };
      delete newConfigs[cityName];
      return newConfigs;
    });
    setMetricsData((prev) => {
      const newData = { ...prev };
      delete newData[cityName];
      return newData;
    });
    setExpandedForms((prev) => {
      const newForms = { ...prev };
      delete newForms[cityName];
      return newForms;
    });
  };

  const availableCities = cities.filter(
    (city) => !configuredCities.includes(city)
  );

  const cityLaunches = Object.entries(approvedConfigs).reduce(
    (acc, [cityName, config]) => {
      acc[cityName] = config.launch_date;
      return acc;
    },
    {} as { [key: string]: string }
  );

  const handleSaveGlobalParameters = async (parameters: GlobalParameters) => {
    try {
      setGlobalParameters(parameters);
      setMaxMarketingSpending(parameters.max_marketing_spending);

      // First, save the parameters to the backend
      await api.saveGlobalParameters(parameters);

      // Then fetch the updated global operations metrics
      await api.getGlobalOperationsMetrics();

      // Update metrics for all configured cities in parallel
      const updatedMetricsData: { [key: string]: MetricsResponse } = {};
      await Promise.all(
        configuredCities.map(async (cityName) => {
          if (cityConfigs[cityName]) {
            const metrics = await api.getCityMetrics(
              cityName,
              cityConfigs[cityName]
            );
            updatedMetricsData[cityName] = metrics;
          }
        })
      );

      setMetricsData(updatedMetricsData);

      // Regenerate aggregated metrics if treehouse is expanded
      if (isTreehouseExpanded) {
        // Pass the new global operations metrics to avoid another API call
        await generateAggregatedMetrics();
      }
    } catch (err) {
      setError(
        err instanceof Error
          ? err.message
          : "Failed to update global parameters"
      );
    }
  };

  const handleExportAllMetrics = async () => {
    try {
      const [allMetrics, globalOpsMetrics] = await Promise.all([
        Promise.all(
          Object.keys(approvedConfigs).map(async (city) =>
            api.getCityMetrics(city, approvedConfigs[city])
          )
        ),
        api.getGlobalOperationsMetrics(),
      ]);

      const citiesData = Object.fromEntries(
        Object.keys(approvedConfigs).map((city, index) => [
          city,
          allMetrics[index].metrics,
        ])
      );

      await exportAllCityMetrics(citiesData, approvedConfigs, globalOpsMetrics);
    } catch (error) {
      console.error("Error exporting metrics:", error);
    }
  };

  const handleScenarioSelect = async (citiesToLoad: string[]) => {
    try {
      setError(null);
      
      // Clear existing cities first
      setConfiguredCities([]);
      setCityConfigs({});
      setMetricsData({});
      setExpandedForms({});
      setApprovedConfigs({});

      // Load each city in the scenario
      for (const cityName of citiesToLoad) {
        const config = await api.getCityConfig(cityName);
        const data = await api.getCityMetrics(cityName, config);

        setConfiguredCities(prev => [...prev, cityName]);
        setCityConfigs(prev => ({ ...prev, [cityName]: config }));
        setMetricsData(prev => ({ ...prev, [cityName]: data }));
        setExpandedForms(prev => ({ ...prev, [cityName]: true }));
        setApprovedConfigs(prev => ({ ...prev, [cityName]: config }));
      }

      // Generate aggregated metrics for the new scenario
      await generateAggregatedMetrics();
    } catch (err) {
      setError(
        err instanceof Error 
          ? err.message 
          : "Failed to load scenario cities"
      );
    }
  };

  return (
    <div className="h-screen flex flex-col bg-[#1C1C18] text-white">
      {/* Fixed Header */}
      <div className="bg-[#1C1C18] border-b border-[#2C2C28]">
        <div className="container mx-auto p-4">
          <div className="flex items-center justify-between mb-8">
            <div className="flex items-center gap-4">
              <div className="flex items-center gap-2">
                <img src={logo} alt="Logo" className="h-8" />
                <h1 className="text-2xl font-bold">City Growth Visualizer</h1>
                <button
                  onClick={() => setShowSettings(true)}
                  className="p-2 hover:bg-[#3C3C38] rounded-full ml-2"
                >
                  <Cog6ToothIcon className="h-6 w-6 text-[#01FF95]" />
                </button>
              </div>

              {Object.keys(approvedConfigs).length > 0 && (
                <div 
                  className="bg-[#2C2C28] p-3 rounded-lg shadow-lg cursor-pointer hover:bg-[#3C3C38] transition-colors"
                  onClick={() => {
                    setIsTreehouseExpanded(!isTreehouseExpanded);
                    if (!aggregatedMetrics) {
                      generateAggregatedMetrics();
                    }
                  }}
                >
                  <div className="flex items-center gap-4">
                    <h2 className="text-lg font-bold text-[#01FF95]">
                      Treehouse Metrics
                    </h2>
                    <span className="text-[#01FF95]">
                      {isTreehouseExpanded ? "▼" : "▲"}
                    </span>
                    <TreehouseMetrics 
                      configs={approvedConfigs} 
                      compact 
                      onClick={() => {
                        setIsTreehouseExpanded(!isTreehouseExpanded);
                        if (!aggregatedMetrics) {
                          generateAggregatedMetrics();
                        }
                      }}
                    />
                  </div>
                </div>
              )}
            </div>

            <select
              onChange={(e) => handleAddCity(e.target.value)}
              value=""
              className="bg-[#3C3C38] border-[#4C4C48] border p-2 rounded min-w-[150px]"
            >
              <option value="">Add a city</option>
              {availableCities.map((city) => (
                <option key={city} value={city}>
                  {city}
                </option>
              ))}
            </select>
          </div>

          {/* Expanded Treehouse Visualizations */}
          {isTreehouseExpanded && aggregatedMetrics && (
            <div className="mt-4 bg-[#2C2C28] p-4 rounded-lg shadow-lg">
              <h2 className="text-xl font-bold mb-4 text-[#01FF95]">
                Aggregated Metrics
              </h2>
              <EnhancedVisualizations
                data={aggregatedMetrics}
                totalProviders={Object.values(approvedConfigs).reduce(
                  (sum, config) => sum + config.providers_total_number,
                  0
                )}
                providerPenetration={
                  Object.values(approvedConfigs).reduce(
                    (sum, config) => sum + config.provider_target_penetration,
                    0
                  ) / Object.keys(approvedConfigs).length
                }
                cityLaunches={cityLaunches}
                rampingPeriodMonths={
                  Object.values(approvedConfigs)[0]
                    ?.providers_ramping_period_months
                }
                showExportButton={true}
                onExportMetrics={handleExportAllMetrics}
              />
            </div>
          )}

          {error && (
            <div className="mt-4 p-4 bg-red-500 text-white rounded-lg">
              {error}
            </div>
          )}
        </div>
      </div>

      {/* Scrollable Content */}
      <div className="flex-1 overflow-auto">
        <div className="container mx-auto p-4">
          <div className="space-y-6">
            {configuredCities.map((cityName) => (
              <div key={cityName}>
                <div className="flex justify-between items-center mb-2">
                  <div className="flex items-center gap-2">
                    <div
                      onClick={() =>
                        setExpandedForms((prev) => ({
                          ...prev,
                          [cityName]: !prev[cityName],
                        }))
                      }
                      className="flex items-center gap-2 cursor-pointer hover:opacity-80"
                    >
                      <h2 className="text-xl font-bold text-[#01FF95]">
                        {cityName}
                      </h2>
                      <button className="text-[#01FF95]">
                        {expandedForms[cityName] ? "▼" : "▲"}
                      </button>
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <button
                      onClick={() => handleApproveConfig(cityName)}
                      className={`px-4 py-2 rounded ${
                        approvedConfigs[cityName]
                          ? "bg-[#01FF95] text-black hover:bg-[#00CC78]"
                          : "bg-[#FFFF09] text-black hover:bg-[#CCCC07]"
                      }`}
                    >
                      {approvedConfigs[cityName] ? "Approved" : "Not Approved"}
                    </button>
                    <button
                      onClick={() => {
                        if (metricsData[cityName]) {
                          exportCityMetrics(
                            cityName,
                            metricsData[cityName].metrics,
                            cityConfigs[cityName]
                          );
                        }
                      }}
                      className="px-4 py-2 rounded bg-[#4C4C48] text-white hover:bg-[#5C5C58]"
                    >
                      Export
                    </button>
                    <button
                      onClick={() => handleRemoveCity(cityName)}
                      className="text-red-500 hover:text-red-400 p-2 rounded"
                    >
                      <XMarkIcon className="h-5 w-5" />
                    </button>
                  </div>
                </div>

                {expandedForms[cityName] && (
                  <div className="flex gap-6">
                    {/* City Form */}
                    <div className="w-1/2 bg-[#2C2C28] p-6 rounded-lg shadow-lg">
                      <CityForm
                        config={cityConfigs[cityName]}
                        onSave={(config) =>
                          handleConfigChange(cityName, config)
                        }
                        hideButtons={true}
                        maxMarketingSpending={maxMarketingSpending}
                      />
                      <div className="flex items-center gap-4 mt-4">
                        <button
                          onClick={() =>
                            handleSaveConfig(cityName, cityConfigs[cityName])
                          }
                          className="bg-[#01FF95] text-black px-4 py-2 rounded"
                        >
                          Save Changes
                        </button>
                      </div>
                    </div>

                    {/* City Metrics */}
                    <div className="w-1/2">
                      {metricsData[cityName] && (
                        <div className="bg-[#2C2C28] p-6 rounded-lg shadow-lg">
                          <EnhancedVisualizations
                            data={metricsData[cityName]}
                            totalProviders={
                              cityConfigs[cityName].providers_total_number
                            }
                            providerPenetration={
                              cityConfigs[cityName].provider_target_penetration
                            }
                            launchDate={cityConfigs[cityName].launch_date}
                            rampingPeriodMonths={
                              cityConfigs[cityName]
                                .providers_ramping_period_months
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      {showSettings && globalParameters && (
        <SettingsModal
          parameters={globalParameters}
          onSave={handleSaveGlobalParameters}
          onClose={() => setShowSettings(false)}
          onScenarioSelect={handleScenarioSelect}
        />
      )}
    </div>
  );
};
