import React from 'react';
import { MetricsData } from '../types/metrics';

interface MetricsBoxesProps {
    data: MetricsData[];
    selectedDate: string;
    onDateChange: (date: string) => void;
}

export const MetricsBoxes: React.FC<MetricsBoxesProps> = ({ data, selectedDate, onDateChange }) => {
    // Calculate cumulative metrics up to selected date
    const metricsUntilDate = data.filter(metric => metric.date <= selectedDate);
    
    const totalTransactions = metricsUntilDate.reduce((sum, metric) => sum + metric.total_transactions, 0);
    const totalPaidTransactions = metricsUntilDate.reduce((sum, metric) => sum + metric.paid_transactions, 0);
    const totalRevenue = metricsUntilDate.reduce((sum, metric) => sum + metric.revenue, 0);

    // Get all available dates from data using Array.from instead of spread operator
    const dates = Array.from(new Set(data.map(metric => metric.date))).sort();

    return (
        <div className="mt-6 p-4 bg-[#2C2C28] rounded-lg">
            <div className="flex items-center justify-between mb-4">
                <label className="text-[#01FF95]">Select Date:</label>
                <select 
                    value={selectedDate}
                    onChange={(e) => onDateChange(e.target.value)}
                    className="bg-[#3C3C38] border-[#4C4C48] border p-2 rounded"
                >
                    {dates.map(date => (
                        <option key={date} value={date}>{date}</option>
                    ))}
                </select>
            </div>
            <div className="grid grid-cols-3 gap-4">
                <div className="bg-[#3C3C38] p-4 rounded">
                    <h3 className="text-sm text-gray-400 mb-2">Total Transactions</h3>
                    <p className="text-2xl font-bold text-[#01FF95]">{totalTransactions.toLocaleString()}</p>
                </div>
                <div className="bg-[#3C3C38] p-4 rounded">
                    <h3 className="text-sm text-gray-400 mb-2">Paid Transactions</h3>
                    <p className="text-2xl font-bold text-[#01FF95]">{totalPaidTransactions.toLocaleString()}</p>
                </div>
                <div className="bg-[#3C3C38] p-4 rounded">
                    <h3 className="text-sm text-gray-400 mb-2">Total Revenue</h3>
                    <p className="text-2xl font-bold text-[#01FF95]">${totalRevenue.toLocaleString()}</p>
                </div>
            </div>
        </div>
    );
};
