import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { MetricsData } from '../types/metrics';

interface CostStackedBarChartProps {
    data: MetricsData[];
}

export const CostStackedBarChart: React.FC<CostStackedBarChartProps> = ({ data }) => {
    console.log('Global Operations Data:', data.map(item => ({
        date: item
    })));

    // First, let's preprocess the data to convert undefined to 0
    const processedData = data.map(item => ({
        ...item,
        global_operations_total_cost: item.global_operations_total_cost ?? 0
    }));

    return (
        <div className="h-[400px] w-full">
            <ResponsiveContainer>
                <BarChart data={processedData} margin={{ top: 20, right: 60, left: 60, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" stroke="#3C3C38" />
                    <XAxis dataKey="date" stroke="#ffffff" />
                    <YAxis 
                        stroke="#01FF95"
                        tickFormatter={(value) => value.toLocaleString()}
                    />
                    <Tooltip 
                        contentStyle={{ backgroundColor: '#2C2C28', border: 'none', borderRadius: '4px' }}
                        labelStyle={{ color: '#ffffff' }}
                        formatter={(value: any) => Number(value).toLocaleString()}
                    />
                    <Legend />
                    <Bar 
                        dataKey="aaas_costs" 
                        stackId="costs" 
                        fill="#FF4444" 
                        name="AaaS Costs"
                    />
                    <Bar 
                        dataKey="city_operations_staff_cost" 
                        stackId="costs" 
                        fill="#01FF95" 
                        name="Staff Costs"
                    />
                    <Bar 
                        dataKey="city_operations_infrastructure_cost" 
                        stackId="costs" 
                        fill="#FFFF09" 
                        name="Infrastructure Costs"
                    />
                    <Bar 
                        dataKey="city_operations_marketing_cost" 
                        stackId="costs" 
                        fill="#FFB800" 
                        name="Marketing Costs"
                    />
                    <Bar 
                        dataKey="onboarding_cost" 
                        stackId="costs" 
                        fill="#00CC78" 
                        name="Onboarding Costs"
                    />
                    <Bar 
                        dataKey="global_operations_total_cost" 
                        stackId="costs" 
                        fill="#FF8800" 
                        name="Global Operations Costs"
                    />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};