import React from 'react';
import { CityConfig } from '../types/city';

interface TreehouseMetricsProps {
    configs: {[key: string]: CityConfig};
    compact?: boolean;
    onClick?: () => void;
}

const TreehouseMetrics: React.FC<TreehouseMetricsProps> = ({ configs, compact = false, onClick }) => {
    const totalCities = Object.keys(configs).length;
    const totalProviders = Object.values(configs).reduce(
        (sum, config) => sum + config.providers_total_number, 
        0
    );

    if (compact) {
        return (
            <div 
                className="flex gap-6 cursor-pointer hover:opacity-80" 
                onClick={onClick}
            >
                <div className="text-sm">
                    <span className="text-[#01FF95] font-bold">{totalCities}</span> Cities
                </div>
                <div className="text-sm">
                    <span className="text-[#01FF95] font-bold">{totalProviders}</span> Providers
                </div>
            </div>
        );
    }

    return (
        <div className="grid grid-cols-3 gap-6">
            <div className="bg-[#3C3C38] p-4 rounded">
                <h3 className="text-lg font-medium mb-2">Total Cities</h3>
                <p className="text-2xl font-bold text-[#01FF95]">{totalCities}</p>
            </div>
            <div className="bg-[#3C3C38] p-4 rounded">
                <h3 className="text-lg font-medium mb-2">Total Providers</h3>
                <p className="text-2xl font-bold text-[#01FF95]">{totalProviders}</p>
            </div>
        </div>
    );
};

export default TreehouseMetrics;
